import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { IoWallet } from "react-icons/io5";
import { FaArrowLeftLong, FaXmark } from "react-icons/fa6";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import stripeService from "../../Services/stripeService";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import walletService from "../../Services/walletService";
import orderService from "../../Services/orderService";
import { LoadingDots } from "../../Components/LoadingDots";
const stripePromise = loadStripe(
  "pk_test_51NpsCXBA5mbdD8e2Tg8MVBCXtomGyF11MzP1eFRceziDGIOGxMwmjToCNFLQEc2zXeYnBUhk89oKcJ9ffXpSikqU00bObmoUIu"
);

const Accounts = () => {
  const [trigger, setTrigger] = useState(true);
  const [balance, setBalance] = useState();

  const [paidAmount, setPaidAmount] = useState();
  const [total, setTotal] = useState();
  const [unpaid, setUnpaid] = useState();

  const [data, setData] = useState({
    amount: "0",
  });

  const [errors, setErrors] = useState({
    amount: false,
  });

  const [regex, setRegex] = useState({
    amount: /.{1,50}$/,
  });

  useEffect(() => {
    walletService.fetchAmount(4).then((res) => {
      setBalance(res.wallet.amount);
    });
  }, [trigger]);

  useEffect(() => {
    orderService.fetchCustomerPaid(4).then((res) => {
      setPaidAmount(res.orderPaid.paid_amount);
    });

    orderService.fetchCustomerTotal(4).then((res) => {
      setTotal(res.orderTotal.amount);
    });

    orderService.fetchCustomerUnpaid(4).then((res) => {
      setUnpaid(res.orders[0].balance);
    });
  }, []);

  const handleChange = (v, n) => {
    setData((d) => ({ ...d, [n]: v }));
    if (v.length > 0) {
      setErrors((prevState) => ({ ...prevState, [n]: false }));
    }
  };

  let payment = { success: false };
  const childRef = useRef(payment);

  const handleSubmit = async () => {
    if (data.amount === 0) {
      toast.error("Please Enter Valid Amount");
      return;
    }
    let hasError = false;
    Object.keys(data).map((v) => {
      if (!regex[v]?.test(data[v])) {
        setErrors((prevState) => ({ ...prevState, [v]: true }));
        hasError = true;
      }
    });

    if (hasError) {
      toast.error("Please fill the form correctly", { id: "1" });
    } else {
      const response = await childRef.current.pay();
      if (response.success) {
        walletService.chargeAmount(4, data).then((res) => {
          toast.success("Amount Charged Successfully", { id: "2" });
          setShowTopUpScreen(false);
          setTrigger(!trigger);
          setData((prev) => ({ ...prev, amount: 0 }));
        });
      } else {
        toast.error("Unable to proceed payment", { id: "3" });
      }
    }
  };

  const [showTopUpScreen, setShowTopUpScreen] = useState(false);
  return (
    <div>
      <h1 className="flex justify-center text-center font-extrabold text-4xl text-primary mt-10">
        Account
      </h1>
      <div className="flex flex-col bg-white gap-5 md:m-10 m-4 mt-4 md:px-32 md:py-20 rounded-2xl">
        <div className="w-[100%] text-white md:bg-gray-100 bg-white p-6 rounded-xl">
          <div className="flex justify-between items-center">
            <h1 className="font-bold text-3xl text-gray-700">Balance</h1>
            <IoWallet className="text-3xl text-gray-700" />
          </div>
          <div className="flex justify-center mt-10">
            <h1 className="text-center font-medium text-green-900 text-7xl">
              {" "}
              {balance ? balance : <LoadingDots />}
            </h1>
            <h1 className="font-extrabold text-gray-500">AED</h1>
          </div>
          {/* <div className="flex justify-end">
            <button
              className="flex justify-center right-2 mt-10 px-4 py-2 text-base text-white font-bold bg-green-800 rounded-lg"
              onClick={() => setShowTopUpScreen(true)}
            >
              Top up Balance
            </button>
          </div> */}
        </div>

        {showTopUpScreen ? (
          <div className="bg-gray-100 pb-12 px-12 pt-2 md:rounded-md transform transition duration-500 ease-in-out -translate-y-10 opacity-0 animate-slide-in">
            <div className="flex justify-end -mr-5">
              <button
                className="flex justify-end self-end items-center px-1 text-black py-1 rounded-full ml-8 mt-4"
                onClick={() => setShowTopUpScreen(false)}
              >
                <FaXmark className="h-5 w-5" />
              </button>
            </div>
            <div className="bg-white p-4 mx-20 rounded-xl shadow-md mt-5">
              <h1 className="text-3xl text-primary font-extrabold flex justify-center mt-6">
                Top Up Details
              </h1>
              <div className="flex flex-col items-center justify-center w-[80%] gap-3 mt-5 px-[3%] mx-auto">
                <div className="w-full">
                  <label className="text-left tracking-wide text-grey-darker text-[1.2rem] font-[600] mb-[3px]">
                    Amount
                  </label>
                  <input
                    value={parseFloat(data.amount).toFixed(2)}
                    name="amount"
                    onChange={(e) =>
                      handleChange(e.target.value, e.target.name)
                    }
                    className={`py-2 border-gray-200 focus:outline-none focus:border-primary w-full
      px-4 bg-gray-200 bg-opacity-90 rounded-lg ${errors["amount"] ? "border border-red-500" : ""
                      } p-0.5`}
                  />
                </div>
                <div className="w-full">
                  <label className="text-left tracking-wide text-grey-darker text-[1.1rem] font-[600] mb-[3px]">
                    Card Details
                  </label>
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret:
                        "pi_3Q6lazBA5mbdD8e206s1wPwC_secret_ZOzBMzHBHfVaf9jGEaCrODDSJ",
                    }}
                  >
                    <Payment
                      amount={parseFloat(data.amount).toFixed(2)}
                      ref={childRef}
                    />
                  </Elements>
                </div>
              </div>

              <div className="flex justify-center mt-5 mb-5">
                <button
                  className="bg-primary w-40 text-white font-bold py-2 ml-5 rounded-md mt-4"
                  onClick={() => handleSubmit()}
                >
                  Top Up
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="grid grid-cols-3 md:gap-4 gap-1 mt-10">
          <div className="bg-slate-800 shadow-lg p-4 rounded-xl">
            <h1 className="font-bold text-sm md:text-base text-gray-400">
              Total Order Amount
            </h1>
            <div className="flex justify-center mt-4 md:mt-8 mb-4">
              <h1 className="flex justify-center text-center font-medium text-white text-2xl md:text-4xl">
                {total ? total : <LoadingDots />}
              </h1>
              <h1 className="font-extrabold text-gray-500 text-xs md:text-sm">
                AED
              </h1>
            </div>
          </div>

          <div className="bg-slate-800 shadow-lg p-4 rounded-xl">
            <h1 className="font-bold text-sm md:text-base text-gray-400">
              Paid Amount
            </h1>
            <div className="flex justify-center mt-8 mb-4">
              <h1 className="flex justify-center text-center font-medium text-white text-2xl md:text-4xl">
                {paidAmount ? paidAmount : <LoadingDots />}
              </h1>
              <h1 className="font-extrabold text-gray-500 text-xs md:text-sm">
                AED
              </h1>
            </div>
          </div>

          <div className="bg-slate-800 shadow-lg p-4 rounded-xl">
            <h1 className="font-bold text-sm md:text-base text-gray-400">
              Pending Payment
            </h1>
            <div className="flex justify-center mt-8 mb-4">
              <h1 className="flex justify-center text-center font-medium text-white text-2xl md:text-4xl">
                {unpaid ? unpaid : <LoadingDots />}
              </h1>
              <h1 className="font-extrabold text-gray-500 text-xs md:text-sm">
                AED
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Payment = forwardRef(({ amount }, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  useImperativeHandle(ref, () => ({
    async pay() {
      let price = parseInt(amount.toString().replaceAll(".", ""));
      return await stripeService
        .createIntent({ price: price })
        .then(async (response) => {
          if (!stripe || !elements) {
            return { success: false };
          }

          const cardElement = elements.getElement(CardElement);

          const { error, paymentIntent } = await stripe.confirmCardPayment(
            response.clientSecret,
            {
              payment_method: {
                card: cardElement,
              },
            }
          );

          if (error) {
            return { success: false };
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            return { success: true };
          }
        });
    },
  }));

  return (
    <CardElement
      options={{
        style: {
          base: {
            fontWeight: "700",
            fontFamily: "inherit",
            color: "#000000",
            "::placeholder": {
              color: "#afafaf",
              fontWeight: "500",
            },
          },
        },
        hidePostalCode: true,
      }}
      className={`py-3 border border-gray-200 focus:outline-none focus:border-primary  w-full px-3 bg-gray-400 bg-opacity-20 rounded-lg p-0.5`}
    />
  );
});

export default Accounts;
