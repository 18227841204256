import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from "moment";
import React, { useEffect, useState } from "react";
import orderService from "../../Services/orderService";
import Loading from "./Loading";
import { CgClose } from 'react-icons/cg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '12px'
};

export default function CustomerRemarks({ open, handleClose, handleOpen, singleOrder }) {


    const [loading, setLoading] = useState(false);
    const [Notes, setNotes] = useState({})
    const [error, setError] = useState(false);
    const [openNotes, setOpenNotes] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [data, setData] = useState({
        Notes: ""
    })



    const OpenModal = (order) => {
        setNotes(order);
        setOpenNotes(true);
    };

    const closeModal = () => {
        setOpenNotes(false);
        setNotes(null);
    };



    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className=''
            >
                <Box sx={style}>
                    <div className='flex justify-end'>
                        <CgClose className='cursor-pointer m-2 text-red-600' onClick={handleClose} />
                    </div>
                    <div className="my-4 mx-10 lg:mx-12">
                        {loading ?
                            <div className="mt-20">
                                <Loading />
                            </div>
                            :

                            <div>
                                <div className='text-center font-bold text-2xl'> Add Notes to your Orders </div>
                                <div className='flex flex-col mt-6'>
                                    <label className='text-gray-900 text-lg'> Enter Your Remarks </label>
                                    <textarea
                                        type='text'
                                        rows="4"
                                        value={data.Notes}
                                        name='notes'
                                        placeholder='please enter your remarks'
                                        className='p-2 border-2 border-black focus:border-stone-300 text-gray-200'
                                    />
                                </div>
                                <div className='flex justify-center mt-5'>
                                    <button className='bg-red-500 p-1  rounded-md text-white px-3 hover:bg-red-600 '>
                                        Add Note
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </Box>
            </Modal>
        </div >
    );
}