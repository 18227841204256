import axiosInstance from "./axiosInstance";

const newsService = {
    create: async (payload) => {
        try {
            const response = await axiosInstance.post('/posts/create', payload);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchAllNews: async () => {
        try {
            const response = await axiosInstance.get('/posts/all');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    fetchTotal: async () => {
        try {
            const response = await axiosInstance.get('/posts/total');
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
    delete: async (id) => {
        try {
            const response = await axiosInstance.delete('/posts/delete/' + id);
            return response.data
        } catch (error) {
            throw error.response.data
        }
    },
}

export default newsService