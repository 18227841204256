import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Home from "./Screens/Home/Home";
import Layout from './Components/Layout'
import ContactUs from "./Screens/ContactUs/ContactUs";
import AboutUs from "./Screens/AboutUs/AboutUs";
import PortalLayout from "./CRM/PortalLayout";
import Login from "./Screens/Login/Login";
import CreateOrder from "./Screens/CreateOrder/CreateOrder";
import Checkout from "./Screens/Checkout/Checkout";
import Register from "./Screens/Register/Register";
import SuccessPayment from "./Screens/SuccessPayment/SuccessPayment";
import Cookies from "js-cookie";
import PendingDetailView from "./CRM/Modals/OrderDetailsView";
import { useEffect, useState } from "react";
import News from "./Screens/News/News";

export default function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    const location = useLocation()

    useEffect(() => {
        setIsAuthenticated(Cookies.get("REJTLUNVU1RPTUVSLUFVVEhFTlRJQ0FUSU9OLVNFQ1JFVC1LRVk="))
    }, [location.pathname]);

    return (
        <Layout>
            <Routes>
                <Route path="*" element={<Navigate to="/" />} />
                <Route path="/" element={<Home />} />
                <Route path="/pending" element={<PendingDetailView />} />
                <Route path="/crm/*" element={<PortalLayout />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/news" element={<News />} />
                <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" />} />
                <Route path="/signup" element={!isAuthenticated ? <Register /> : <Navigate to="/" />} />
                <Route path="/createOrder" element={<CreateOrder />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/successPayment" element={<SuccessPayment />} />
            </Routes>
        </Layout>

    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>);
