import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import orderService from "../../Services/orderService";
import moment from "moment";

const CustomerLedger = () => {
    const [loading, setLoading] = useState(false);
    const [ledger, setLedger] = useState([]);
    const [customer, setCustomer] = useState(null);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const customerData = JSON.parse(localStorage.getItem("customer"));
    const customerId = customerData?.id;
    console.log(customerId)

    useEffect(() => {
        const getCustomerLedger = async () => {
            try {
                setLoading(true);
                const response = await orderService.fetchCustomerLedger(customerId);

                // Set customer and ledger data separately
                setCustomer(response.customer);
                setLedger(response.ledger);

                setLoading(false);
            } catch (error) {
                console.error("Error fetching customer ledger:", error);
                setLoading(false);
            }
        };
        getCustomerLedger();
    }, []);

    return (
        <div className="px-24 py-10">
            {customer && (
                <div>
                    <div className="bg-white shadow-lg px-8 py-4 mt-2 border-gray-300 border-2 rounded-3xl">
                        <div className="grid grid-cols-3 items-top">
                            <div>
                                <div className="text-primary text-lg font-semibold flex flex-row items-center gap-2">
                                    <h1>{customer.first_name}</h1>
                                    <h1 className="text-sm border border-gray-400 px-3 rounded-full text-blue-600">{customer.customer_type}</h1>
                                </div>
                                <div className="text-xl ">
                                    {customer.company_name}{" "}
                                    <span className="text-sm text-gray-500">(Private Limited)</span>
                                </div>
                            </div>
                            <div className="text-xl text-gray-500"> {customer.email}</div>
                            <div className="text-xl text-gray-500">{customer.phone_number}</div>
                        </div>
                        <div className="text-xl text-gray-500 mt-3">
                            {customer.address}
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="flex flex-row gap-12 justify-end">
                            <div className="flex gap-3 items-center">
                                <label className="text-md text-gray-700 font-bold">Start Date:</label>
                                <input type="date" name="startDate" className="border-b-2 border-gray-400 focus:outline-none focus:border-primary" />
                            </div>
                            <div className="flex gap-3 items-center">
                                <label className="text-md text-gray-700 font-bold">End Date:</label>
                                <input type="date" name="endDate" className="border-b-2 border-gray-400 focus:outline-none focus:border-primary" />
                            </div>
                        </div>
                        <table className="animate_animated animate_fadeIn border-separate border-spacing-y-4">
                            <thead className="bg-primary shadow-lg border-separate-2 border-spacing-y-2">
                                <tr className="uppercase text-sm leading-normal w-full text-white">
                                    <th className="rounded-l-lg p-[1.5%] text-left text-[13px] w-[1%]">
                                        Date
                                    </th>
                                    <th className="p-[1.5%] text-left text-[13px] w-[2%]">
                                        Note
                                    </th>
                                    <th className="p-[1.5%] text-left text-[13px] w-[2%]">
                                        Reference
                                    </th>
                                    <th className="p-[1.5%] text-left text-[13px] w-[1%]">
                                        Debit
                                    </th>
                                    <th className="p-[1.5%] text-left text-[13px] w-[1%]">
                                        Credit
                                    </th>
                                    <th className="rounded-r-lg p-[1.5%] text-left text-[13px] w-[1%]">
                                        Balance
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {ledger.map((entry, index) => (
                                    <tr key={index} className="rounded-lg border border-gray-400 shadow-sm shadow-gray-300">
                                        <td className="py-[1%] px-[2%] text-left rounded-l-lg text-[15px] text-primary font-bold w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                                            {entry.order_date ? moment(entry.order_date).format("ll") : moment(entry.date).format("ll")}
                                        </td>
                                        <td className="py-[1%] px-[2%] text-left text-[15px] text-gray-700 font-light w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                                            {entry.note ? entry.note : "-"}
                                        </td>
                                        <td className="py-[1%] px-[2%] text-left text-[15px] text-gray-700 font-light w-[2%] bg-gray-50 border-b border-primary border-opacity-25">
                                            {entry.type === "ORDER" ? `Order ${entry.invoice}` : `Payment for Order ${entry.invoice}`}
                                        </td>
                                        <td className="py-[1%] px-[2%] text-left text-[15px] font-medium w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                                            {entry.type === "ORDER" ? entry.amount : "-"}
                                        </td>
                                        <td className="py-[1%] px-[2%] text-left text-[15px] font-medium w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                                            {entry.type === "TRANSACTION" ? entry.amount : "-"}
                                        </td>
                                        <td className="py-[1%] px-[2%] rounded-r-lg text-left text-[15px] font-light w-[1%] bg-gray-50 border-b border-primary border-opacity-25">
                                            {parseFloat(entry.balance).toFixed(2)}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-4 mt-3">
                        <div className="text-2xl font-semibold text-primary">End Balance:</div>
                        <div className="text-lg font-normal text-gray-700">{parseFloat(ledger[ledger.length - 1]?.balance).toFixed(2)}</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CustomerLedger;
