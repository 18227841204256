import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar/Sidebar';
import Topbar from './Topbar/Topbar';
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ContactUs from "../Screens/ContactUs/ContactUs";
import Accounts from "./Screens/Accounts";
import CompanyProfile from './Screens/CompanyProfile';
import Documents from '../Screens/Documents/Documents';
import OrderDetail from "./Screens/OrderDetail";
import AddOrderDetails from './Screens/AddOrderDetails';
import Cookies from "js-cookie";
import CustomerLedger from './Screens/CustomerLedger';

const PortalLayout = () => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = Cookies.get("REJTLUNVU1RPTUVSLUFVVEhFTlRJQ0FUSU9OLVNFQ1JFVC1LRVk=");
        if (!token) {
            navigate('/', { replace: true });
        }
    }, [navigate]);

    const toggleSidebar = () => {
        setIsOpen(prev => !prev);
    };

    return (
        <div className='flex min-h-screen'>
            <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <div className='lg:w-full w-full bg-[#F3F4F6]'>
                <div className='h-20'>
                    <Topbar toggleSidebar={toggleSidebar} />
                </div>
                <div className='w-full'>
                    <Routes>
                        <Route path="*" element={<Navigate to="/crm/details" />} />
                        <Route path="/details" element={<CompanyProfile />} />
                        <Route path="/orders" element={<OrderDetail />} />
                        <Route path="/orders/add" element={<AddOrderDetails />} />
                        <Route path="/accounts" element={<Accounts />} />
                        <Route path="/documents" element={<Documents />} />
                        <Route path="/ledger" element={<CustomerLedger />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default PortalLayout;
