import React, { useState, useEffect } from 'react';
import DeliveryDate from '../Modals/DeliveryDate';
import orderService from '../../Services/orderService';
import moment from "moment/moment";
import Tooltips from './Tooltip';
import OrderDetailsView from '../Modals/OrderDetailsView';
import Loading from '../Modals/Loading';
import Payment from '../Modals/Payment';
import PaymentModal from '../Modals/PaymentModal';

const Shipment = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [Shipment, setShipment] = useState()
  const [orders, setOrders] = useState([])
  const customerProfile = JSON.parse(localStorage.getItem('customer'))
  const [singleOrder, setSingleOrder] = useState({})
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  // const [selectedOrder, setSelectedOrder] = useState(null);
  const [isReceiptOpen, setIsReceiptOpen] = useState(false);
  const [trigger, setTrigger] = useState(false)
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isOpen, setIsOpen] = useState(false)

  const OpenPaymentModal = (id) => {
    setSelectedOrderId(id)
    setIsOpen(true);
  };

  const closePaymentModal = () => {
    setIsOpen(false);
  };

  const OpenReceiptModal = (order) => {
    setSelectedOrder(order);
    setIsReceiptOpen(true);
  };

  const closeReceiptModal = () => {
    setIsReceiptOpen(false);
    setSelectedOrder(null);
  };

  const OpenDetailsModal = (order) => {
    setSingleOrder(order);
    setOpen(true);
  };

  const closeDetailsModal = () => {
    setOpen(false);
    setSingleOrder(null);
  };

  useEffect(() => {
    const getShipment = async () => {
      try {
        setLoading(true)
        const response = await orderService.fetchReadyToDeliverOrders(customerProfile.id)
        setOrders(response.orders)
        setLoading(false)
      }
      catch {

      }
    }
    getShipment()
  }, [trigger])

  const OpenModal = (order) => {
    setSelectedOrder(order);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
  };
  return (
    <div className="flex flex-col w-[100%]">
      {
        orders.length ?
          <table className=" w-full">
            <thead>
              <tr className="bg-primary uppercase text-xs leading-normal w-full text-white">
                <th className="py-6 rounded-tl-xl text-center text-[12px] w-[8%]">
                  Order No.
                </th>
                <th className="py-2 text-center text-[12px] w-[8%]">Date of Order</th>
                <th className="py-2  text-center text-[12px] w-[8%]">Paid Amount</th>
                <th className=" py-2 text-center text-[12px] w-[8%]">
                  Remaining Amount
                </th>
                <th className=" py-2 text-center text-[12px] w-[8%]">
                  Payment Status
                </th>
                <th className=" py-2 text-center text-[12px] w-[8%]">
                  Delivery Schedule
                </th>
                <th className="py-2 text-center text-[12px] w-[8%]">Payment</th>
                <th className=" py-2 text-center text-[12px] w-[8%]">
                  Receipt
                </th>
                <th className=" py-2 rounded-tr-xl text-center text-[12px] w-[8%]">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {orders.map((value, index) => (
                <tr key={index} className="border-b border-gray-200">
                  <td className="p-[2%] text-center font-bold text-[12px] w-[8%] bg-white">
                    {value.invoice}
                  </td>
                  <td className="p-[2%] text-center text-[12px] w-[8%] bg-white">
                    {moment(value.created_at).format("ll")}
                  </td>
                  <td className="p-[2%] text-center text-[12px] w-[8%] bg-white">
                    {value.paid_amount}
                  </td>
                  <td className="p-[2%] text-center text-[12px] w-[8%] bg-white">
                    {value.amount - value.paid_amount}
                  </td>
                  <td className="p-[2%] text-center text-[10px] w-[8%] bg-white">
                    <span
                      className={`text-white px-4 py-1 rounded-md uppercase font-bold ${value.amount - value.paid_amount !== 0 ? "bg-yellow-500" : value.payment_status === "paid" ? "bg-green-600" : "bg-red-500"} `}>{value.amount - value.paid_amount !== 0 ? "partial" : value.payment_status === "paid" ? "Paid" : "Unpaid"}</span>
                  </td>
                  <td className=" p-[2%] text-center text-[12px] w-[8%] bg-white">
                    {
                      !value.delivery_date ?
                        <button
                          className="bg-green-500 text-white py-1 px-2 rounded-md hover:bg-green-600"
                          onClick={() => OpenModal(value)}
                        >
                          Set Delivery Date
                        </button> :
                        <div>{value.delivery_date}</div>
                    }
                  </td>
                  <td className="p-[2%] text-center text-[12px] w-[8%] bg-white">
                    <button
                      className="bg-green-500 text-white py-2 px-5 font-bold rounded-lg hover:bg-green-600"
                      onClick={() => OpenPaymentModal(value.id)}
                    >
                      Pay Remaining
                    </button>
                  </td>
                  <td className="p-[2%] text-center text-[12px] w-[8%] bg-white">
                    <button
                      className="bg-green-500 text-white py-2 px-5 font-bold rounded-lg hover:bg-green-600"
                      onClick={() => OpenReceiptModal(value.id)}
                    >
                      Upload Receipt
                    </button>
                  </td>
                  <td className="p-[2%] text-center text-[10px] w-[8%] bg-white">
                    <div className="flex flex-row justify-center gap-3">
                      <Tooltips title="View Order">
                        <div
                          onClick={() => OpenDetailsModal(value)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="black"
                            class="size-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                            />
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                            />
                          </svg>
                        </div>
                      </Tooltips>
                      <Tooltips title="Payment">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="green"
                            class="size-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                            />
                          </svg>
                        </div>
                      </Tooltips>
                      <Tooltips title="Excel File">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="2"
                            stroke="blue"
                            class="size-4"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                            />
                          </svg>
                        </div>
                      </Tooltips>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          : <>
            {
              loading === false &&
              <div className="py-6 text-gray-800 text-xl px-12 font-semibold">No Orders Ready for Shipment</div>
            }
          </>
      }
      {
        loading &&
        <div className="flex justify-center mx-auto py-12">
          <Loading />
        </div>
      }
      <PaymentModal
        isOpen={isOpen}
        onClose={closePaymentModal}
        selectedOrderId={selectedOrderId}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      <DeliveryDate
        isOpen={isModalOpen}
        onClose={closeModal}
        order={selectedOrder}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      <Payment
        isOpen={isReceiptOpen}
        onClose={closeReceiptModal}
        order={selectedOrder}
        trigger={trigger}
        setTrigger={setTrigger}
      />
      <OrderDetailsView
        open={open}
        handleOpen={OpenDetailsModal}
        handleClose={closeDetailsModal}
        singleOrder={singleOrder}
      />
    </div>
  );
};

export default Shipment
