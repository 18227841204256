import React, { useState } from 'react';
import uploadService from "../../Services/uploadService";
import orderReceiptService from "../../Services/orderReceiptService";
import { replace, useNavigate } from "react-router-dom";
import Loading from './Loading';

const Payment = ({ isOpen, onClose, order, trigger, setTrigger }) => {
    const [errors, setErrors] = useState({});
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        payment_slip: ""
    });

    const navigate = useNavigate()

    const handleFileChange = (e) => {
        const reader = new FileReader();
        if (e.target.files.length > 0) {
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => {
                setErrors((prevErrors) => ({ ...prevErrors, payment_slip: false }));
                setFile(e.target.files[0]);
                setData((prevData) => ({ ...prevData, payment_slip: reader.result }));
            };
        }
    };

    const handleSubmit = () => {
        if (!file) {
            setErrors((prevErrors) => ({ ...prevErrors, payment_slip: true }));
            return;
        }
        setLoading(true)
        uploadService.single(file).then(res => {
            let data = { order: order, receipt: res.response.url }
            orderReceiptService.create(data).then(response => {
                if (response.responseCode === 200) {
                    setLoading(false)
                    setTrigger(!trigger)
                    onClose()
                    navigate('/crm/orders', { replace: true })
                }
            })
            setLoading(false)
        })
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-gray-100 p-6 rounded-md shadow-lg w-[500px]">
                <h2 className="text-xl font-semibold mb-4">Upload Payment Proof</h2>
                {
                    loading ? <Loading /> :
                        <div className="mb-4">

                            <input
                                type="file"
                                onChange={handleFileChange}
                                className={`w-full py-1 text-sm px-2 bg-gray-100 rounded-sm focus:outline-none border 
                        ${errors['payment_slip'] ? "border-red-600" : "border-gray-300"} 
                        focus:border-gray-300 file:mr-4 file:py-[7px] file:px-6 file:rounded-sm
                        file:border-0 file:text-[12px] file:font-semibold
                        file:bg-white file:text-black hover:file:bg-primary hover:file:text-white`}
                            />
                            {errors['payment_slip'] && (
                                <span className="text-red-500 text-sm">Upload a payment Slip </span>
                            )}
                        </div>
                }

                <div className="flex justify-end space-x-3">
                    <button
                        className="px-4 py-1 bg-red-600 text-white rounded-md"
                        onClick={() => onClose()}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-4 py-1 bg-blue-600 text-white rounded-md"
                        onClick={() => handleSubmit()}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Payment;
